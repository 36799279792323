body {
  margin: 0;
  font-family: "tesco modern";
  background-color: #f6f6f6 !important;
  margin-bottom: 20px;
  padding-bottom: 40px;
}

code {
  font-family: "tesco modern"
}

.MuiCardActionArea-focusHighlight {
  opacity: 0 !important;
}
.tescoLogo {
  height: 70px;
  width: 175px;
}

.ceLogo {
  height: 70px;
  width: 175px;
  right: 0;
  position: absolute;
}

.header {
  background-color: #FFFFFF;
  height: 80px;
  width: 100%;
  border-bottom: solid 2px #ddd;
  overflow: hidden;
}

.Logo {
  height: 60px;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #00539F !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #00539F !important;
}

.MuiSvgIcon-root {
  width: 20px !important;
  height: 40px !important;
}

/* .MuiOutlinedInput-root {
  padding: 4px 2px 4px 2px !important;
} */

.MuiStepConnector-alternativeLabel {
  top: 20px !important;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

/* Tesco branding */
@font-face {
  font-family: tesco modern;
  font-display: swap;
  font-weight: 400;
  src: url("./assets/fonts/tescomodreg-web.woff2") format("woff2"),
    url("./assets/fonts/tescomodreg-web.woff") format("woff"),
    url("./assets/fonts/TESCOModern-Regular-final.ttf") format("truetype"),
    url("./assets/fonts/tescomodreg-web.eot") format("embedded-opentype")
}

@font-face {
  font-family: tesco modern;
  font-display: swap;
  font-weight: 700;
  src: url("./assets/fonts/tescomodbol-web.woff2") format("woff2"),
    url("./assets/fonts/tescomodbol-web.woff") format("woff"),
    url("./assets/fonts/TESCOModern-Bold.ttf") format("truetype"),
    url("./assets/fonts/tescomodbol-web.eot") format("embedded-opentype")
}


.MuiButton-containedPrimary {
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "tesco modern" !important;
  border-radius: 25px !important;
  color: #F7F5F5 !important;
}

.MuiButton-startIcon {
  margin-right: 0 !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #00539F !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #91BAE3 !important;
}

.MuiSelect-icon {
  top: auto !important;
}

.pageTitles {
  font-size: 32px !important;
  letter-spacing: -0.8px !important;
  font-family: "tesco modern" !important;
  color: #575252 !important;
  font-weight: bold !important;
}

.pageSubTitles {
  font-size: 28px !important;
  letter-spacing: -0.4px !important;
  font-family: "tesco modern" !important;
  color: #575252 !important;
  font-weight: bold !important;
}

.headings {
  font-size: 20px !important;
  letter-spacing: -0px !important;
  font-family: "tesco modern" !important;
  color: #575252 !important;
  font-weight: bold !important;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "tesco modern" !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.links {
  color: #00539F !important;
  font-weight: bold;
}

.bodyText {
  font-size: 18px;
  font-family: "tesco modern";
  color: #575252;
  line-height: 1.5;

}

.MuiSelect-iconOutlined {
  color: #91BAE3 !important;
}

.--Paper-shadow {
  padding-top: 10;
  box-shadow: '2px 3px 8px 0 rgba(0, 0, 0, .2)';
  border-radius: '6px';
}

.MuiButton-root {
  text-transform: none !important;
}

.MuiSelect-select.Mui-disabled {
  cursor: not-allowed;
  background: #e1e4eaa3;
}